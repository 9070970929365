import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";
import { mainBlue, mainWhite, screen } from "../components/variables";
import { ImArrowLeft2 } from "react-icons/im";

const Wrapper = styled.div`
  background: ${mainBlue};
  color: ${mainWhite};
  min-height: calc(100vh - 100px);
  padding: 70px 0 0 0;

  .title {
    font-size: 1.4rem;
    @media ${screen.xsmall} {
      font-size: 1.95rem;
    }
    @media ${screen.small} {
      font-size: 2.15rem;
    }

    .break {
      display: block;
    }
  }

  .btn {
    border: 2px ${mainWhite} solid;
    color: ${mainWhite};
    text-transform: uppercase;
    margin: 30px 0 0 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 25px 4px 44px;
    display: inline-block;
    @media ${screen.withCursor} {
      &:hover {
        background: ${mainWhite};
        color: ${mainBlue};
      }
    }

    svg {
      font-size: 1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout rotatedSquareColour={mainBlue}>
      <Seo title="404: Not found" />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="title">
            <span className="break">We couldn’t find</span> what you’re looking
            for.
          </h1>
          <Link className="btn" to="/">
            <ImArrowLeft2 />
            TAKE ME HOME
          </Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
